import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ButtonBlock: {
    id: 'Button Block',
    defaultMessage: 'Button',
  },
  script: {
    id: 'Script Path',
    defaultMessage: 'Script Path',
  }
});

export const ButtonSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.ButtonBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['script'],
      },
    ],

    properties: {
      script: {
        title: props.intl.formatMessage(messages.script),
        type: 'string'
      },
  
    },
    required: [],
  };
};
