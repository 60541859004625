import React, { useEffect, useState, useContext, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Cookies from 'js-cookie';
// import CookieContext from '../../../hochschul-frontpage/src/customizations/components/theme/CookieProvider';

const messages = defineMessages({
  ButtonText: {
    id: 'Button text',
    defaultMessage: 'Button text',
  },
});

const View = ({ data, isEditMode, className }) => {

  const [isFunctionalEnabled, setIsFunctionalEnabled] = useState();

  useEffect(() => {
    const cookieValue = Cookies.get('cc_cookie');

    if (cookieValue) {
      try {
        const cookieData = JSON.parse(cookieValue);
        if (cookieData.categories && cookieData.categories.includes('functional')) {
          setIsFunctionalEnabled(true);
        } else {
          setIsFunctionalEnabled(false);
        }

      } catch (error) {
        console.error('Error parsing cc_cookie:', error);
      }
    }
  });

  const containerRef = useRef(null);

  const loadFundraiser = () => {
    var body = document.getElementsByTagName('body')[0];
    if (!body) {
      window.setTimeout(loadFundraiser, 10);
      return;
    }
    if (containerRef.current) {
      var script = document.createElement('script');
      /*       script.src = "https://secure.fundraisingbox.com/app/paymentJS?hash=z6v7428gdevohs3v";
       */
      script.src = data.script? data.script : "https://secure.fundraisingbox.com/app/paymentJS?hash=z6v7428gdevohs3v";
      containerRef.current.insertBefore(script, containerRef.current.firstChild);
      return;
    }

  };

  useEffect(() => {
    loadFundraiser();
  }, []);

  return (
    <div ref={containerRef} className='spenden-block'>
      <div id='fbIframeDiv' />
      <a target="_blank" href="https://www.fundraisingbox.com/?utm_source=donation_form">
        <img border="0" style={{ border: "0 !important" }} src="https://secure.fundraisingbox.com/images/FundraisingBox-Logo-Widget.png" alt="FundraisingBox Logo" />
      </a>
    </div>
  );
};

export default View;
