import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Container } from '@plone/components';
import MobileNavigation from '../MobileNavigation/MobileNavigation';
import { useIntl, defineMessages } from 'react-intl';
import config from '@plone/volto/registry';
import cx from 'classnames';
import heartIcon from './heart-fill.svg';
import { Link } from 'react-router-dom';
import {
  Anontools,
  LanguageSelector,
  Logo,
  Navigation,
  SearchWidget,
  UniversalLink,
} from '@plone/volto/components';
import Cookies from 'js-cookie';
import { Button } from 'semantic-ui-react';
import CookieContext from '../CookieProvider/CookieProvider';

const messages = defineMessages({
  siteLabel: {
    id: 'siteLabel',
    defaultMessage: ' ',
  },
});

const InternetHeader = ({ pathname, siteLabel, token, siteAction }) => {
  const loadFundraiser = () => {
    var body = document.getElementsByTagName('body')[0];
    if (!body) {
      window.setTimeout(loadAltruja, 10);
      return;
    }
    var script = document.createElement('script');
    script.src = "https://secure.fundraisingbox.com/app/paymentJS?hash=z6v7428gdevohs3v";
    body.insertBefore(script, body.firstChild);
  };

  useEffect(() => {
/*     loadFundraiser();
 */  }, []);

  return (
    <div className="header">
      <div className="logo-nav-wrapper">
        <div className="logo">
          <Logo />
        </div>
        <Navigation pathname={pathname} />
        <MobileNavigation pathname={pathname} />
      </div>
    </div>
  );
};

const Header = ({ pathname }) => {

  const [isFunctionalEnabled, setIsFunctionalEnabled] = useState();

  useEffect(() => {
    const cookieValue = Cookies.get('cc_cookie');

    if (cookieValue) {
      try {
        const cookieData = JSON.parse(cookieValue);
        if (cookieData.categories && cookieData.categories.includes('functional')) {
          setIsFunctionalEnabled(true);
        } else {
          setIsFunctionalEnabled(false);
        }

      } catch (error) {
        console.error('Error parsing cc_cookie:', error);
      }
    }
  });

  const siteLabelConfig = config.settings.siteLabel;
  const intranetHeader = config.settings.intranetHeader;
  const token = useSelector((state) => state.userSession.token);
  const siteAction = useSelector(
    (state) => state.content.data?.['@components']?.actions?.site_actions,
  );
  const intl = useIntl();
  const translatedSiteLabel = intl.formatMessage(messages.siteLabel);

  const siteLabel =
    siteLabelConfig &&
    (translatedSiteLabel !== 'siteLabel' && translatedSiteLabel !== ' '
      ? translatedSiteLabel
      : siteLabelConfig);

  const donationButton = (
    <UniversalLink href="/spenden/"
      className="spenden-button"
    >
      <button>
        {"Spenden"}
        <span
          className="heart-icon"
          style={{ backgroundImage: `url(${heartIcon})` }}
        ></span>
      </button>
    </UniversalLink>
  );

  const donationButtonOld = isFunctionalEnabled ? (
    <a
      id="ef-bl-x7jn2nd9j"
      href="/spenden/?ef-page=1&spendenstiftung"
      className="spenden-button"
    >
      <button>
        {"Spenden"}
        <span
          className="heart-icon"
          style={{ backgroundImage: `url(${heartIcon})` }}
        ></span>
      </button>
    </a>
  ) : (
    <div
      id="ef-bl-x7jn2nd9j"
      className="spenden-button disabled"
    >
      <button className="cookie-button" type="button" data-cc="show-preferencesModal">
        {"Spenden"}
        <span
          className="heart-icon"
          style={{ backgroundImage: `url(${heartIcon})` }}
        ></span>
      </button>
    </div>
  );

  return (
    <>
      <div className="sticky-try">
        <div className="tools-wrapper">
          <Container layout>
            <LanguageSelector />
            <div className="tools-bar">
              <div className="tools">
                {!token && <Anontools />}
                <UniversalLink href={"/kontakt"}>
                  {"Kontakt"}
                </UniversalLink>
                <UniversalLink href={"/impressum"}>
                  {"Impressum"}
                </UniversalLink>
              </div>
              {donationButton}
            </div>
          </Container>
        </div>
      </div>
      <header
        className={cx('header-wrapper', { 'intranet-header': intranetHeader })}
      >
        <Container layout>
          <InternetHeader
            pathname={pathname}
            siteLabel={siteLabel}
            token={token}
            siteAction={siteAction}
          />
        </Container>
      </header>
    </>
  );
};

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
};

Header.defaultProps = {
  token: null,
};

export default Header;
